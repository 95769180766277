import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get In Touch`}</h2>
    <p>{`You can contact me on my business site `}<a href="https://www.zector.io/">{`Zecter LLC`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      