import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ProjectCard title="Zector LLC" link="https://www.zector.io/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Developed the website for this LLC #BlockchainTechnology.
    </ProjectCard>
    <ProjectCard title="A+ ADVISOR" link="https://priceless-babbage-4f6e9d.netlify.app" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
Pet project entry to Natural Language Processing (NLP) #ChallengeYourPerspective.
    </ProjectCard>
    <ProjectCard title="Enjoy Coin" link="https://www.enjoycoin.io/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
A Fin-Tech Mobile App built on blockchain technology. Available both in the App Store and Google Play Store #CryptoCurrency.
    </ProjectCard>
    <ProjectCard title="COMING SOON" link="https://www.behance.net/gallery/58937147/Freiheit" bg="linear-gradient(to right, rgb(213, 133, 255) 0%, rgb(0, 255, 238) 100%)" mdxType="ProjectCard">
Some Cool VR/AR Applications.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      