import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <h5>{`The world as we know it is vastly changing with technolgy at the forefront. My goal is to continue driving such changes with the results positively impacting society.`}</h5>
    <p>{`My Journey began in a small town where the occupational norm was to be a farmer or carpenter. There were very little career advancement opportunities or exposure to technology.`}</p>
    <p>{`Ironically, that changed for me upon arriving to the states as I was exposed to technology. I was first a consumer; then, I transitioned into a developer. Along the years, I have progressed and acquired many skills and hobbies, overcame challenges one after another, and learned a lot about the world I will soon impact.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      